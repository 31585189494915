<template>
  <fieldset :class="{ box: !isViewModeCreate }">
    <page-title
      :title="$t('page.neq.title')"
      @edit="$emit('edit')"
    >
      <p class="has-text-justified">
        {{ $t('page.neq.description') }}
      </p>
      <p class="has-text-justified">
        {{ $t('page.neq.reqwarning') }}
      </p>
    </page-title>
    <b-field
      :label="getFieldLabel('neq')"
      :type="getFieldType('neq')"
      :message="getFieldMessage('neq')"
    >
      <b-input
        v-if="isFieldEditable(`$.neq.neq`)"
        v-model="neq"
        autocomplete="off"
        :disabled="loading"
        custom-class="page-focus-first"
      >
      </b-input>
      <span v-else>{{ model.neq }}</span>
    </b-field>
    <b-button
      v-if="isViewModeCreate"
      type="is-primary"
      @click="fetchNeq"
      :loading="loading"
      :disabled="!validNeqAndDifferent"
    >
      {{ $t('page.neq.search') }}
    </b-button>
    <b-message
      v-if="failed"
      class="my-5"
      type="is-danger"
    >
      {{ $t('page.neq.failedToLoadNeq') }}
    </b-message>
    <b-message
      v-if="model.nomEntreprise && isViewModeCreate"
      class="my-5"
      type="is-success"
    >
      {{ model.nomEntreprise }}
    </b-message>
  </fieldset>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import page from '@/mixins/page';
import api from '@/api';

const NEQ_REGEX = /^1[0-9]{9}$/;

export default {
  name: 'PageNeq',
  mixins: [
    page('neq')
  ],
  data () {
    return {
      failed: false,
      loading: false,
      neq: ''
    };
  },
  computed: {
    ...mapState('form', [
      'currentForm'
    ]),
    validNeqAndDifferent () {
      return NEQ_REGEX.test(this.neq) && this.neq != this.model.neq;
    }
  },
  mounted () {
    this.neq = this.model.neq;
    if (!this.model.nomEntreprise) {
      this.disableNextStep();
    }
  },
  methods: {
    ...mapActions('form', [
      'enableNextStep',
      'disableNextStep',
      'resetModel'
    ]),
    fetchNeq () {
      this.loading = true;
      this.failed = false;
      this.model.nomEntreprise = undefined;
      api.fetchNeq(this.neq)
        .then(({ data }) => {
          this.resetModel();
          this.model = { ...data };
          this.loading = false;
          this.enableNextStep();
        })
        .catch(() => {
          this.loading = false;
          this.failed = true;
          this.disableNextStep();
          this.resetModel();
        });
    },
    validateNEQ () {
      this.validateRegex('neq', this.neq, NEQ_REGEX);
    },
    validate () {
      this.validateNEQ();
    }
  }
};
</script>
